@font-face {
  font-family: Gotham;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Gotham), url("Gotham-Black.44cc8f32.woff2") format("woff2");
  unicoderange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF";
}

.keen-slider:not([data-keen-slider-disabled]) {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  align-content: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
  flex-direction: row-reverse;
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
  flex-wrap: wrap;
}
/*# sourceMappingURL=index.9835ba55.css.map */
